/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function SLA() {
  return (
    <>
      <section className="sub_page_banner_section">
        <div className="container">
          <div className="row banner_row sub_page_banner_content">
            <div className="col-xl-8 col-lg-7 col-md-6 order02 banner_content_box sub_page_banner_content_inner">
              <small>Cam kết</small>
              <h1>Chất lượng dịch vụ</h1>
              <p>
                Chúng tôi đảm bảo dịch vụ của Quý khách có chất lượng dịch vụ
                tốt nhất.
              </p>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-6 order01">
              <figure>
                <img src="assets/images/sub_page_banner_img01.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section
        className="padding_top_60 padding_bottom_100 text-center bg_d8eff5 shared_guarantee_section"
        data-scroll-index={1}
      >
        <div className="container">
          <div className="section_heading margin_bottom_80">
            <h2 className="color_051646" style={{ fontSize: "24px" }}>
              Chúng tôi đảm bảo dịch vụ của Quý khách đang sử dụng đạt uptime từ
              99,99% trở lên.
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 margin_mobile">
              <div className="guarantee_box">
                <figure>
                  <img src="assets/images/svg/guarantee_img01.svg" alt="" />
                </figure>
                <h4>100% Host Node Uptime</h4>
                <p>
                  Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn được
                  thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm kỹ sư
                  của chúng tôi sử dụng giám sát tích cực để chủ động phát hiện
                  các vấn đề và thực hiện các biện pháp phòng ngừa, giảm thiểu
                  bất kỳ tác động nào khi phần cứng máy chủ bị lỗi có thể gây ra
                  đối với môi trường của bạn.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 margin_mobile">
              <div className="guarantee_box">
                <figure>
                  <img src="assets/images/svg/guarantee_img01.svg" alt="" />
                </figure>
                <h4>100% Host Node Uptime</h4>
                <p>
                  Nền tảng phần cứng lưu trữ các phiên bản máy tính của bạn được
                  thiết kế với mục tiêu chính là ổn định. Đồng thời, nhóm kỹ sư
                  của chúng tôi sử dụng giám sát tích cực để chủ động phát hiện
                  các vấn đề và thực hiện các biện pháp phòng ngừa, giảm thiểu
                  bất kỳ tác động nào khi phần cứng máy chủ bị lỗi có thể gây ra
                  đối với môi trường của bạn.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding_bottom_100 padding_top_100 text-center  ">
        <div className="container">
          <div className="section_heading margin_bottom_80">
            <h2 className="color_051646">Cam Kết Đền Bù Phí Dịch Vụ</h2>
            <p>
              Trường hợp tỷ lệ hoạt động hàng tháng của máy chủ ảo giảm xuống
              dưới 99,9%, chúng tôi sẽ khấu trừ số tiền được tính theo tỷ lệ
              hiển thị bên dưới cho chi phí sử dụng trong tháng xảy ra sự cố.
            </p>
          </div>
          <table
            className="large-only table domain_table stacktable aos-init aos-animate"
            data-aos="fade-up"
          >
            <thead className="bg_230fae">
              <tr className="">
                <th className="">Thời gian uptime hàng tháng</th>
                <th className="">Thời gian ở trạng thái không sẵn sàng</th>
                <th className="">Mức phí dịch vụ đền bù</th>
              </tr>
            </thead>
            <tbody className="domain_table_body">
              <tr>
                <td>Từ 99,9% tới 100%</td>
                <td>Tối đa 43 phút</td>
                <td>0%</td>
              </tr>
              <tr>
                <td>Từ 99% tới ít hơn 99,9%</td>
                <td>Tối đa 430 phút</td>
                <td>30%</td>
              </tr>
              <tr>
                <td>Từ 90% tới ít hơn 99%</td>
                <td>Tối đa 4300 phút</td>
                <td>50%</td>
              </tr>
              <tr>
                <td>Ít hơn 90%</td>
                <td>Hơn 4300 phút</td>
                <td>100%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function DedicatedServerCategory() {
  const navigate = useNavigate();
  return (
    <div
      className="dropdown-menu default_dd_menu header_footer_dd whmcs_dd"
      aria-labelledby="navbarDropdown"
    >
      <a
        className="dropdown-item d-flex"
        href=""
        onClick={() => {
          navigate(URL.ListDedicatedServerEU);
        }}
      >
        <img
          style={{
            alignSelf: "center",
            marginRight: "6px",
          }}
          src="assets/flags/european-union.png"
          alt="server"
          width={35}
          height={35}
        ></img>
        <span style={{ alignSelf: "center" }}>Dediacated Châu Âu</span>
      </a>
      <a
        className="dropdown-item d-flex"
        href=""
        onClick={() => {
          navigate(URL.ListDedicatedServerUS);
        }}
      >
        <img
          style={{
            alignSelf: "center",
            marginRight: "6px",
          }}
          src="assets/flags/united-states.png"
          alt="server"
          width={35}
          height={35}
        ></img>
        <span style={{ alignSelf: "center" }}>Dediacated Mỹ</span>
      </a>
    </div>
  );
}

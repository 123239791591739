/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Contact() {
  return (
    <>
      <section className="sub_page_banner_section">
        <div className="container">
          <div className="row banner_row sub_page_banner_content">
            <div className="col-xl-8 col-lg-6 col-md-6 order02 banner_content_box sub_page_banner_content_inner">
              <h1>Hãy liên hệ!</h1>
              <p>
                Thời gian là tiền bạc! Chúng tôi xem trọng khách hàng và thời
                gian của mọi người, vì vậy, đội ngũ của chúng tôi luôn làm tốt
                nhất có thể để giúp bạn giải quyết vấn đề nhanh chóng!
              </p>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 order01">
              <figure>
                <img src="assets/images/sub_page_banner_img06.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="bg_d8eff5 padding_top_100 text-center">
        <div className="container">
          <div className="section_heading margin_bottom_80">
            <h2 className="color_051646">Liên Hệ Với Chúng Tôi</h2>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 margin_mobile">
              <div
                className="mailing_address_box"
                style={{
                  paddingBottom: "96px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <figure>
                  <img src="assets/images/svg/mailing_box_img02.svg" alt="" />
                </figure>
                <h3>Admin</h3>
                <ul className="contact_list">
                  <li style={{ fontSize: "18px" }}>
                    Facebook:{" "}
                    <a
                      style={{ fontSize: "18px" }}
                      href="https://www.facebook.com/chinh.vpspro"
                    >
                      https://www.facebook.com/chinh.vpspro
                    </a>
                  </li>
                  <li style={{ fontSize: "18px" }}>
                    Telegram:{" "}
                    <a
                      style={{ fontSize: "18px" }}
                      href="https://t.me/chinhvpspro"
                    >
                      @chinhvpspro
                    </a>
                  </li>
                  <li style={{ fontSize: "18px" }}>
                    Zalo: <a style={{ fontSize: "18px" }}>0356297874</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="location_map" style={{ marginBottom: "60px" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3723.1995715439134!2d105.797974675842!3d21.064689886542364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135abad5d66c4f9%3A0x280efee4fcba2dd2!2zTjAzVDcgTmdv4bqhaSBHaWFvIMSQb8Ogbg!5e0!3m2!1svi!2s!4v1700665536284!5m2!1svi!2s"
          width="100%"
          height="450"
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
}
